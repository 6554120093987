<template>
	<section>
		<TitleBar title="Satınalma Talebi Detayları" :modi="modi" />

		<b-tabs>
			<b-tab-item class="columns is-multiline" label="Genel">
				<b-field class="column is-6 box" label="Tanım" grouped group-multiline>
					<b-field class="column is-12" label="ID">
						<b-input v-model="purchaserequest.id" disabled></b-input>
					</b-field>
					<b-field class="column is-12" label="Açıklama">
						<b-input
							v-model="purchaserequest.name"
							:disabled="modi == 'show'"
						></b-input>
					</b-field>
				</b-field>

				<b-field class="column is-6 box" label="Durum" grouped group-multiline>
					<b-radio-button
						class="column is-6"
						size="is-small"
						type="is-danger is-light"
						native-value="Açık"
						v-model="purchaserequest.status"
						:disabled="modi == 'show'"
						expanded
					>
						Açık
					</b-radio-button>
					<b-radio-button
						class="column is-6"
						size="is-small"
						type="is-warning is-light"
						native-value="Askıda"
						v-model="purchaserequest.status"
						:disabled="modi == 'show'"
						expanded
					>
						Askıda
					</b-radio-button>
					<b-radio-button
						class="column is-6"
						size="is-small"
						type="is-success is-light"
						native-value="Kapalı"
						v-model="purchaserequest.status"
						:disabled="modi == 'show'"
						expanded
					>
						Kapalı
					</b-radio-button>
					<b-radio-button
						class="column is-6"
						size="is-small"
						type="is-light"
						native-value="İptal"
						v-model="purchaserequest.status"
						:disabled="modi == 'show'"
						expanded
					>
						İptal
					</b-radio-button>
				</b-field>

				<b-field class="column is-12 box" label="Notlar" grouped group-multiline>
					<b-input
						class="column is-12"
						type="textarea"
						v-model="purchaserequest.note"
						:rows="5"
						:disabled="modi == 'show'"
					></b-input>
				</b-field>

				<b-field
					class="column is-12 box"
					label="Satınalma Talebi Kalemleri Tablosu"
					grouped
					group-multiline
				>
					<b-field
						class="column is-12"
						position="is-right"
						grouped
						group-multiline
					>
						<b-button
							class="column"
							type="is-success is-light"
							icon-left="plus"
							label="Ekle"
							@click="bom_add"
							:disabled="modi == 'show'"
						/>
						<b-button
							class="column ml-1"
							type="is-warning is-light"
							icon-left="import"
							label="Temizle"
							disabled
						/>
						<b-button
							class="column ml-1"
							type="is-link is-light"
							icon-left="import"
							label="Yazdır"
						/>
						<b-button
							class="column ml-1"
							type="is-link is-light"
							icon-left="export"
							label="Dışa Aktar"
						/>
					</b-field>

					<b-table
						class="column is-12"
						:key="$store.state.ui.flush"
						:data="purchaserequest.boms"
						:mobile-cards="false"
						striped
						narrowed
					>
						<template slot="empty">
							<div class="is-12 has-text-centered">
								Satınalma talebi kalem kaydı bulunamadı.
							</div>
						</template>

						<b-table-column field="id" label="ID" v-slot="props" sortable>{{
							props.row.id.slice(-5)
						}}</b-table-column>
						<b-table-column
							field="item.code"
							label="Malzeme Kodu"
							v-slot="props"
							sortable
						>
							<MaterialAutofill
								v-model="props.row.item"
								:disabled="!!props.row.id"
							/>
						</b-table-column>
						<b-table-column
							field="item.name"
							label="Malzeme Açıklaması"
							v-slot="props"
							sortable
							>{{ props.row.item.name }}</b-table-column
						>
						<b-table-column
							field="quantity"
							label="Miktar (KG)"
							v-slot="props"
							sortable
						>
							<b-numberinput
								type="is-link is-light"
								placeholder="0.00"
								controls-alignment="right"
								controls-position="compact"
								v-model="props.row.quantity"
								:disabled="modi == 'show'"
								expanded
							></b-numberinput>
						</b-table-column>
						<b-table-column field="action" label="Aksiyon" v-slot="props">
							<b-button
								type="is-danger is-light"
								icon-left="delete"
								@click="bom_delete(props.row.id, props.index)"
								:disabled="modi == 'show'"
							/>
						</b-table-column>
					</b-table>
				</b-field>
			</b-tab-item>

			<b-tab-item label="Kayıt Geçmişi" :disabled="!purchaserequest.id">
				<MetaDetails v-if="$route.query.id" />
			</b-tab-item>
		</b-tabs>

		<b-field position="is-right">
			<b-button
				class="ml-1"
				type="is-success is-light"
				icon-left="check"
				label="Kaydet"
				:disabled="modi == 'show'"
				@click="purchaserequest_save"
			/>
			<b-button
				class="ml-1"
				type="is-danger is-light"
				icon-left="close"
				label="İptal"
				@click="purchaserequest_cancel"
			/>
		</b-field>
	</section>
</template>

<script>
import gql from "graphql-tag";
import TitleBar from "@/components/TitleBar.vue";
import MaterialAutofill from "@/components/MaterialAutofill.vue";
import MetaDetails from "@/components/MetaDetails.vue";

export default {
	name: "PurchaseRequestDetails",
	components: {
		TitleBar,
		MaterialAutofill,
		MetaDetails,
	},
	data: () => ({
		modi: "",
		purchaserequest: {
			id: "",
			boms: [],
		},
	}),
	created() {
		this.purchaserequest.id = this.$route.query.id || "";
		this.modi = this.$route.query.modi || "add";
		this.purchaserequest_read();
	},
	methods: {
		purchaserequest_read() {
			if (this.modi == "add") return;
			this.$apollo.queries.readPurchaseRequest.start();
		},
		purchaserequest_save() {
			var err = "";
			var payload = this.purchaserequest;
			delete payload.createdat;
			delete payload.createdby;

			if (!payload.name) err = "Satınalma talebi açıklaması boş olamaz.";
			if (!payload.status) err = "Durum boş olamaz.";

			if (err)
				return this.$buefy.snackbar.open({
					type: "is-warning",
					message: err,
					position: "is-bottom-right",
				});

			this.$apollo
				.mutate({
					mutation: gql`
						mutation($purchaserequest: String) {
							savePurchaseRequest(purchaserequest: $purchaserequest)
						}
					`,
					variables: {
						purchaserequest: JSON.stringify(payload),
					},
				})
				.then(() => {
					this.$buefy.snackbar.open({
						type: "is-success",
						message: "Başarılı",
						position: "is-bottom-right",
					});
					return this.$router.push({ path: "/satinalma/talep/liste" });
				})
				.catch(() => {
					return this.$buefy.snackbar.open({
						type: "is-danger",
						message: "Bir hata oluştu.",
						position: "is-bottom-right",
					});
				});
		},
		purchaserequest_cancel() {
			this.$router.push({ path: "/satinalma/talep/liste" });
		},
		bom_add() {
			this.purchaserequest.boms.push({ id: "", item: {}, quantity: 0.0 });
		},
		bom_delete(id, index) {
			if (!id) this.purchaserequest.boms.splice(index, 1);
			else;
		},
	},
	apollo: {
		readPurchaseRequest: {
			query: gql`
				query readPurchaseRequests($config: String) {
					readPurchaseRequests(config: $config) {
						id
						name
						status
						note
						createdat
						boms {
							id
							item {
								id
								code
								name
							}
							quantity
						}
					}
				}
			`,
			variables() {
				return {
					config: JSON.stringify({
						skip: 0,
						take: 1,
						sorts: [],
						filters: { id: this.purchaserequest.id },
					}),
				};
			},
			update(data) {
				this.$apollo.queries.readPurchaseRequest.stop();
				this.purchaserequest = data.readPurchaseRequests[0];
				this.purchaserequest.createdat = new Date(this.purchaserequest.createdat);

				if (this.modi == "ref") {
					delete this.purchaserequest.id;
					for (var bom of this.purchaserequest.boms) delete bom.id;
				}
			},
		},
	},
};
</script>
